import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
 

// material-ui
import { ButtonBase,Box ,Typography} from '@material-ui/core';

import logo from '../../../assets/images/Darpan.jpg'

// project imports
import config from './../../../config';
// import Logo from './../../../ui-component/Logo';
import { MENU_OPEN } from '../../../store/actions';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {

    const dispatch = useDispatch(); // Access the dispatch function from the Redux store

    const handleClick = () => {
        dispatch({ type: MENU_OPEN, id: "news" }); // Dispatch the MENU_OPEN action with your desired ID
    };

    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath} onClick={handleClick}>
  <Box style={{ background: "white", display: "flex", width: "40px", height: "40px", alignItems: "center", justifyContent: "center", borderRadius: "50%" }}>
    <img alt="text" src={logo} height="25px" style={{ filter: "invert(100%) sepia(800%) saturate(0%) hue-rotate(229deg) brightness(0.7) contrast(1.2)" }} />
  </Box>
  <Typography style={{ fontSize: "25px", color: "white", fontFamily: "poppins", marginLeft: "5px", fontWeight: "bold" }}> - Darpan</Typography>
</ButtonBase>

    );
}

export default LogoSection;
