// assets
import { IconBrandFramer, IconNotification, IconBell,  IconWindmill, IconLayoutGridAdd,IconUser,IconFile} from '@tabler/icons';

// constant
const icons = {
    IconTypography: IconBell,
    IconPalette: IconUser,
    IconShadow: IconNotification,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd,
    IconFile:IconFile
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const utilities = {
    id: 'utilities',
    title: 'Others',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: icons['IconPalette'],
            breadcrumbs: false
        },
        {
            id: 'notifications',
            title: 'Notifications',
            type: 'item',
            url: '/notifications',
            icon: icons['IconTypography'],
            breadcrumbs: false
        },
        
        {
            id: 'categories',
            title: 'Categories',
            type: 'item',
            url: '/categories',
            icon: icons['IconShadow'],
            breadcrumbs: false
        },
        {
            id: 'Pdf',
            title: 'Upload Pdf',
            type: 'item',
            url: '/upload',
            icon: icons['IconFile'],
            breadcrumbs: false
        },
       
    ]
};
