// assets
import {  IconDeviceAnalytics,IconNews } from '@tabler/icons';
// import FeedIcon from '@mui/icons-material/Feed';

// constant
const icons = {
    IconDashboard: IconNews,
    IconDeviceAnalytics
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'news',
            title: 'News',
            type: 'item',
            url: '/news',
            icon: icons['IconDashboard'],
            breadcrumbs: false
        }
    ]
};
